import { RouterModule, Routes } from '@angular/router';

// import { PagesComponent } from '.pages/pages.component';

import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { RegisterComponent } from './login/register.component';
// import { PagesComponent } from './pages/pages.component';



const appRoutes: Routes = [
    {path:'login',component:LoginComponent},
    {path:'register',component:RegisterComponent},
    {path:'**',component:NopagefoundComponent}
];

export const APP_ROUTES =  RouterModule.forRoot( appRoutes, { useHash:true });