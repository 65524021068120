import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { UsuarioDetalle } from '../../models/UsuarioDetalle';
import { FileuploadService } from '../../services/shared/fileupload.service';
import {trigger, style, animate, transition} from '@angular/animations';



@Component({
  selector: 'app-datos-basicos',
  templateUrl: './datos-basicos.component.html',
  styles: []
  
})
export class DatosBasicosComponent implements OnInit {
 usuario: UsuarioDetalle = new UsuarioDetalle();

 archivo = {
  nombre: null,
  nombreArchivo: null,
  base64textString: null,
  id:null
  }
  datauser = localStorage.getItem('id_u');
  imagen=null;
  constructor(
    private request: RequestService,
    private uploadService: FileuploadService
  ) { }

  ngOnInit() {

    const datos = {
      id: this.datauser
    }
    this.archivo.id=this.datauser;
    this.request.globalRequest('post', 'infoUsuariosId', datos  ).subscribe((res: any) => {
      this.usuario = res;
      this.imagen=res.avatar;
      // console.log('datos', res);
      });
  }
  seleccionarArchivo(event) {
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;

    if(files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
      setTimeout(() => {
        this.upload();
      }, 400);
      
    }
  }

  _handleReaderLoaded(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.archivo.base64textString = btoa(binaryString);
  }

  upload() {
    console.log(this.archivo);
    this.request.globalRequestW('post','subirImagen',this.archivo).subscribe(res=>{
      console.log(res);
      this.imagen=res.avatar;
    });
  }

  editUsuario(){
    //this.usuario.password_u=this.pw;
    console.log(this.usuario);
    this.request.globalRequestW('post', 'editUsuarios', this.usuario).subscribe(res=>{
      console.log(res);
      document.getElementById('alerta').setAttribute('style','display:inline');
      setTimeout(() => {
        document.getElementById('alerta').setAttribute('style','display:none');
      }, 3000);
    });
  }

 
}
