import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-crear-paquetes',
  templateUrl: './crear-paquetes.component.html',
  styles: []
})
export class CrearPaquetesComponent implements OnInit {
  public roles:[];
  public new_paquete: any;
  constructor(
    private request:RequestService,
    private _router:Router
  ) { 
    this.new_paquete={
      'descripcion'  :'',
      'vacantes_pck':'',
      'destacadas_pck':'',
      'urgentes_pck':'',
      'consbase_pck':'',
      'conspostu_pck':'',
      'valor_pck':'',
      'iva_pck':'',
      'total_pck':'',
      'vigencia_p_pck':'',
      'vigencia_va_pck':'',
	
    }  
  }

  ngOnInit() {
  }
  addPaquete(){
    this.new_paquete.iva_pck=this.new_paquete.valor_pck*0.16;
    this.new_paquete.total_pck=(this.new_paquete.valor_pck*1)+(this.new_paquete.valor_pck*0.16);
    this.request.globalRequestW('post', 'savePaquetes', this.new_paquete).subscribe(res=>{
      console.log(res);
      this._router.navigate(['/consulta-paquetes']);
    });
  }
  cancelar(){
    this._router.navigate(['/consulta-paquetes']);
  }

}
