import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-detalle-postulados',
  templateUrl: './detalle-postulados.component.html',
  styleUrls: []
})
export class DetallePostuladosComponent implements OnInit {
  public postulado:any
  constructor(
    private request:RequestService,
    private _router:Router,
    private _route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe((params:Params)=>{
      console.log(params.id);
      this.request.globalRequestW('post','getPostuladosId',params).subscribe(res=>{
        console.log(res);
        this.postulado=res;
      });
    });
  }

}
