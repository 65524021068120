import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
//import { constants } from 'os';

@Component({
  selector: 'app-consulta-postulados',
  templateUrl: './consulta-postulados.component.html',
  styleUrls: []
})
export class ConsultaPostuladosComponent implements OnInit {
  public postulados: [];
  public rowData: [];
  public columnDefs:any;
  constructor(
    private request:RequestService,
    private _router:Router,
  ) { }

  ngOnInit(): void {
    const _self=this;

    this.columnDefs = [
      {
        width: 50, cellRenderer : function(params){
        return '<button type="button" class="btn btn-outline-info btn-sm" >\
                  <span class="mdi mdi-eye">\
                </span></button>'
        },
        
        onCellClicked: function (params) {
          _self.toDetalle(params.data.id);
        },
        pagination: function(params){
          true
        },
        paginationAutoPageSize: function(params){
          true
        },
      },
      

      {headerName: 'Id Vacante', field: 'id' ,width:120, sortable: true, filter: true,resizable: true, pagination: true },
      {headerName: 'Titulo Vacante', field: 'titulo' , sortable: true, filter: true,resizable: true, pagination: true },
      {headerName: 'Id Postulado', field: 'id_usuario' ,width:120,  sortable: true, filter: true,resizable: true, pagination: true },
      {headerName: 'Nombre Postulado', field: 'nombre' ,  sortable: true, filter: true,resizable: true, pagination: true },
      {headerName: 'Apellido Postulado', field: 'apellidoP' ,  sortable: true, filter: true,resizable: true, pagination: true },
      {headerName: 'Fecha Postulacion', field: 'fecha_postulacion2',width:180, sortable: true, filter: true,resizable: true, pagination: true },
      {headerName: 'Id Epresa', field: 'id_empresa', width:120 , sortable: true, filter: true,resizable: true, pagination: true },
      {headerName: 'Empresa', field: 'nombre_comercial' , sortable: true, filter: true,resizable: true, pagination: true },
    ];
    this.request.globalRequestW('get','getPostulados',{}).subscribe(res=>{
      console.log('acacaca',res);
      this.postulados=res.data;
    });
  }
  toDetalle(id){
    this._router.navigate(['/detalle-postulados/'+id]);
  }
}
