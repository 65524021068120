import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatorService {

  constructor(   private http:HttpClient) { }


  login(form){
   return this.http.post('https://backadmin1.clubvacante.com/source/api/v1/login',form);
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('id_u');
    localStorage.removeItem('empresa_u');
    localStorage.removeItem('rol_u');
    localStorage.removeItem('permisos');
    
  }
}
