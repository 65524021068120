import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

import { Label } from 'ng2-charts';

@Component({
  selector: 'app-graficas3-graficas',
  templateUrl: './graficas3-graficas.component.html',
  styles: []
})
export class Graficas3GraficasComponent implements OnInit {
  public paquete:0;
  public OptionsVacantes: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: 'este es el titulo',
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Periodo de tiempo',
        },
      }], 
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'No Vacantes',
        },
        ticks: {
          min : 0,
        }
      }] 
    },
  };
  public OptionsPostulados: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: 'Estadisticas de la empresa',
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Periodo de tiempo',
        },
      }], 
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Cantidad',
        },
        ticks: {
          min : 0,
          stepSize: 1,
        }
      }] 
    },
  };
  public DataEmpresas: ChartDataSets[] = 
  [
    {
      data:[],
      label:'',
    }
  ];
  public DataEmpVacantes: ChartDataSets[] = 
  [
    {
      data:[],
      label:'Numero de vacantes',
      backgroundColor: 'rgba(51, 216, 255,.7)',
      borderColor: 'rgb(51, 216, 255)',
    }
  ];
  public LabelsEmpVacantes: [];
  public LabelsGraficas: Label[] = [];
  public paquetesTabla:[];
  public columnDefs:any;
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'line';
  public barChartLegend = true;
  //public barChartPlugins = [pluginDataLabels];

 
  fechaInicio: any;
  fechaFin:any;
  constructor(
    private request:RequestService,
    private _router:Router,
    private _route:ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    const _self=this;
    this.columnDefs = [
      {headerName: 'Id', field: 'id' , width: 120, sortable: true, filter: true , resizable: true},
      {headerName: 'Paquete', field: 'descripcion' , width: 120, sortable: true, filter: true , resizable: true},
      {headerName: 'Fecha Inicio', field: 'fecha_activacion2' , width: 120, sortable: true, filter: true , resizable: true},
      {headerName: 'Fecha Fin', field: 'fecha_fin2',  width: 100, sortable: true, filter: true , resizable: true},
      {headerName: 'Estatus', field: 'estatus' ,width: 120, sortable: true, filter: true , resizable: true},
      {headerName: 'Vacantes', field: 'vacantes',width: 120, sortable: true, filter: true, resizable: true},
      {headerName: 'Urgentes', field: 'destacadas',  width: 120,sortable: true, filter: true, resizable: true},
      {headerName: 'Destacados', field: 'urgentes', width: 120, sortable: true, filter: true, resizable: true},
      {headerName: 'Postulados', field: 'postulados',  width: 120,sortable: true, filter: true, resizable: true},
      {headerName: 'Porcentaje', field: 'estatus_pck', width: 120, sortable: true, filter: true, resizable: true},
  ];
    this._route.params.subscribe((params:Params)=>{
      this.paquete=params.id_paquete;
      this.request.globalRequestW('post', 'getEmpresaPaquetes', {id_empresa:params.id_empresa}).subscribe(res => {
        //console.log(res);
        this.paquetesTabla = res;
      });
      console.log(params);
      this.request.globalRequestW('post', 'GraficasEmpresaId', {fInicio:this.fechaInicio, fFin:this.fechaFin,id_empresa:params.id_empresa}).subscribe(res2 => {
        console.log(res2);
        this.LabelsGraficas=res2['arrayFechas'];
        this.DataEmpresas[0]=res2['Vacantes'];
        this.DataEmpresas[1]=res2['Postulados'];
        this.DataEmpresas[2]=res2['Vistas'];
      });
    });
  }
  
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  estGrafica(){
    this._route.params.subscribe((params:Params)=>{
      this.paquete=params.id_paquete;
      this.request.globalRequestW('post', 'getEmpresaPaquetes', {id_empresa:params.id_empresa}).subscribe(res => {
        //console.log(res);
        this.paquetesTabla = res;
      });
      console.log(params);
      this.request.globalRequestW('post', 'GraficasEmpresaId', {fInicio:this.fechaInicio, fFin:this.fechaFin,id_empresa:params.id_empresa}).subscribe(res2 => {
        console.log(res2);
        this.LabelsGraficas=res2['arrayFechas'];
        this.DataEmpresas[0]=res2['Vacantes'];
        this.DataEmpresas[1]=res2['Postulados'];
        this.DataEmpresas[2]=res2['Vistas'];
      });
    });
  }

  
}
