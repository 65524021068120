import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

//ngcharts
import { ChartsModule } from 'ng2-charts';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { GraficodonaComponent } from '../components/graficodona/graficodona.component';
import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';
import {FormsModule } from '@angular/forms';
//temporalmente
import { IncrementadorComponent } from '../components/incrementador/incrementador.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { DatosBasicosComponent } from './datos-basicos/datos-basicos.component';

//import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule} from '@angular/forms';
import { ListadoUsuariosComponent } from './listado-usuarios/listado-usuarios.component';
import { CreaUsuariosComponent } from './crea-usuarios/crea-usuarios.component';
import { CrearEmpresaComponent } from './crear-empresa/crear-empresa.component';
import { ConsultaPaquetesComponent } from './consulta-paquetes/consulta-paquetes.component';
import { ModificaPaquetesComponent } from './modifica-paquetes/modifica-paquetes.component';
import { CrearPaquetesComponent } from './crear-paquetes/crear-paquetes.component';
import { ConsultaCandidatosComponent } from './consulta-candidatos/consulta-candidatos.component';
import { ModificaCandidatosComponent } from './modifica-candidatos/modifica-candidatos.component';
import { ModificaEmpresaComponent } from './modifica-empresa/modifica-empresa.component';
import { ConsultaEmpresaComponent } from './consulta-empresa/consulta-empresa.component';
import { ModificaUsuariosComponent } from './modifica-usuarios/modifica-usuarios.component';
// import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { ConsultaPostuladosComponent } from './consulta-postulados/consulta-postulados.component';
import { DetallePostuladosComponent } from './detalle-postulados/detalle-postulados.component';
// import {MatPaginatorModule} from '@angular/material/paginator';
import { AgGridModule } from 'ag-grid-angular';
import { ConsultaVacantesComponent } from './consulta-vacantes/consulta-vacantes.component';
import { ModificaVacantesComponent } from './modifica-vacantes/modifica-vacantes.component';
import { Graficas2Component } from './graficas2/graficas2.component';
import { Graficas3Component } from './graficas3/graficas3.component';
import { Graficas3GraficasComponent } from './graficas3-graficas/graficas3-graficas.component';
import { NotificacionesEmpresaComponent } from './notificaciones-empresa/notificaciones-empresa.component';
import { NotificacionesCandidatosComponent } from './notificaciones-candidatos/notificaciones-candidatos.component';
import { Graficas3DetalleComponent } from './graficas3-detalle/graficas3-detalle.component';



@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        ProgressComponent,
        Graficas1Component,
        IncrementadorComponent,
        GraficodonaComponent,
        AccountSettingsComponent,
        DatosBasicosComponent,
        ListadoUsuariosComponent,
        CreaUsuariosComponent,
        CrearEmpresaComponent,
        ConsultaPaquetesComponent,
        ModificaPaquetesComponent,
        CrearPaquetesComponent,
        ConsultaCandidatosComponent,
        ModificaCandidatosComponent,
        ModificaEmpresaComponent,
        ConsultaEmpresaComponent,
        ModificaUsuariosComponent,
        ConsultaPostuladosComponent,
        DetallePostuladosComponent,
        ConsultaVacantesComponent,
        ModificaVacantesComponent,
        Graficas2Component,
        Graficas3Component,
        Graficas3GraficasComponent,
        NotificacionesEmpresaComponent,
        NotificacionesCandidatosComponent,
        Graficas3DetalleComponent
    ],
    exports: [
        DashboardComponent,
        ProgressComponent,
        Graficas1Component,
        GraficodonaComponent,
    ],
    imports:[
        SharedModule,
        PAGES_ROUTES,
        FormsModule,
        ChartsModule,
        FormsModule,
        //AgGridModule.withComponents([]),
        // MatCheckboxModule,
        ReactiveFormsModule,
        CommonModule,
        HttpClientModule,
        AgGridModule.withComponents([])
        // MatPaginatorModule
        
    
  
    ]
    
})
export class PagesModule { }
