import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TokenService } from '../token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  URL = environment.urlApiW;
  options: any;
  headers: any;

  constructor(
    private http: HttpClient,
    private token:TokenService
    ) { }

  uploadFile(archivo) {
    console.log(archivo);
    return this.http.post(`${this.URL}subirImagen`, JSON.stringify(archivo));
  }
}
