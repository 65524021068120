import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';


@Component({
  selector: 'app-listado-usuarios',
  templateUrl: './listado-usuarios.component.html',
  styles: []
})
export class ListadoUsuariosComponent implements OnInit {
  public usuarios:[];
  columnDefs:any;

  constructor(
    private request:RequestService,
    private _router:Router,
    ) {}

  ngOnInit() {
    const _self=this;
    this.request.globalRequestW('get', 'infoUsuarios', {}).subscribe(res => {
      // console.log(res);
      //this.rowData = res;
      this.usuarios=res;
    });
    this.columnDefs = [
      {width: 50, cellRenderer : function (params){
        return '<button type="button" class="btn btn-outline-danger btn-sm">\
                  <span class="mdi mdi-do-not-disturb"></span>\
                </button>'
        },
        onCellClicked: function (params) {
          _self.retirar(params.data.id_u);
        }
      },
      {width: 50, cellRenderer : function (params){
        return '<button type="button" class="btn btn-outline-info btn-sm" >\
                  <span class="mdi mdi-pencil"></span>\
                </button>'
        },
        onCellClicked: function (params) {
          _self.toEdit(params.data.id_u);
        }
      },
      {headerName: 'Nombre', field: 'nombre_completo' ,  sortable: true, filter: true },
      {headerName: 'Rol', field: 'texto_p',  sortable: true, filter: true },
      {headerName: 'Fecha Registro', field: 'fecha1',  sortable: true, filter: true },
      {headerName: 'Status', field: 'nombre_estatus' ,width: 90, sortable: true, filter: true },
      {headerName: 'Fecha Retiro', field: 'fecha2', sortable: true, filter: true},
  ];
  }
  optionsRendererFunc(params) {
    var button= document.createElement('button');
    // console.log(params);
    button.innerHTML = 'Del';
    button.addEventListener('click', () => {
       this.toEdit(params.value.id_u);
    });
    return button;
  }

  toAdd(){
    this._router.navigate(['/crear-usuarios']);
  }
  toEdit(id){
    this._router.navigate(['/modificar-usuarios/'+id]);
  }
  retirar(id){
    this.request.globalRequest('post', 'deleteUsuarios',{'id':id}).subscribe(res=>{
      this.request.globalRequest('get','infoUsuarios',{}).subscribe(res=>{
        // console.log(res);
        this.usuarios=res;
      });
    });
  }
  



}
