import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-modifica-vacantes',
  templateUrl: './modifica-vacantes.component.html',
  styleUrls: []
})
export class ModificaVacantesComponent implements OnInit {
  public new_vacante:any;
  public edad_max:18;
  public edad_min:30;
  
  constructor(
    private request:RequestService,
    private _router:Router,
    private _route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe((params:Params)=>{
      console.log(params.id);
      this.request.globalRequestW('post','getVacantesIdS',params).subscribe(res=>{
        this.new_vacante=res[0];
        this.edad_max=this.new_vacante.edad_perfil.split(',')[1];
        this.edad_min=this.new_vacante.edad_perfil.split(',')[0];
        
        console.log(res[0]);
      });
    });
  }
  editVacante(){
    this.new_vacante.edad_perfil=this.edad_min+','+this.edad_max;
    this.request.globalRequestW('post', 'editVacantes', this.new_vacante).subscribe(res=>{
      console.log(res);
      this._router.navigate(['/consulta-vacantes']);
    });
  }
  cancelar(){
    this._router.navigate(['/consulta-vacantes']);
  }
}
