import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsService, SidebarService, SharedService ,AuthenticatorService ,TokenService,AuthService} from './service.index';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    SettingsService, 
    SidebarService, 
    SharedService,
    AuthenticatorService,
    TokenService,
    AuthService
  ]
})
export class ServiceModule { }
