import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-graficas3-detalle',
  templateUrl: './graficas3-detalle.component.html',
  styleUrls: []
})
export class Graficas3DetalleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
