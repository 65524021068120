import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

import { Label } from 'ng2-charts';

@Component({
  selector: 'app-graficas2',
  templateUrl: './graficas2.component.html',
  styles: []
})
export class Graficas2Component implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
       xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Periodo de tiempo'
    },
       }], 
       yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Paquetes activados'
        },
        ticks: {
          min : 0,
        }
       }] 
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'line';
  public barChartLegend = true;
  //public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = 
  [
    {
      data:[],
      label:'',
    }
  ];
  fechaInicio: any;
  fechaFin:any;
  constructor(
    private request:RequestService,
    private _router:Router,
  ) { }

  ngOnInit() {
    console.log(this.barChartData);
    this.request.globalRequestW('post', 'GraficasPaquetes', {fInicio:this.fechaInicio, fFin:this.fechaFin}).subscribe(res => {
      console.log(res);
      //this.rowData = res;
      this.barChartLabels=res['arrayFechas'];
      this.barChartData=res['arrayDatos'];
    });
  }
  estPaquete() {
    console.log(this.barChartData);
    this.request.globalRequestW('post', 'GraficasPaquetes', {fInicio:this.fechaInicio, fFin:this.fechaFin}).subscribe(res => {
      console.log(res);
      //this.rowData = res;
      this.barChartLabels=res['arrayFechas'];
      this.barChartData=res['arrayDatos'];
      //this.barChartData[0].data=res;
    });
  }
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  
}
