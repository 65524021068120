import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';



@Component({
  selector: 'app-crea-usuarios',
  templateUrl: './crea-usuarios.component.html',
  styles: []
})

export class CreaUsuariosComponent implements OnInit {
  public roles:[];
  public new_usuario: any;
  constructor(
    private request:RequestService,
    private _router:Router
  ) {
    this.new_usuario={
      'nombre_u'  :'',
      'apellido_u':'',
      'apellido2_u':'',
      'cargo_u'   :'',
      'area_u'    :'',
      'celular_u' :'',
      'email_u'   :'',
      'password_u':'',
      //'empresa_u' :'',
      'rol_u'     :'',
      'finicio_u' :'',
      'fretiro_u' :'',
      'estado_u'  :'',
      'token_recovery_u':'',
    }  
   }

  ngOnInit() {
    this.request.globalRequest('get', 'getRoles',{}).subscribe(res=>{
      this.roles=res;
    });
  }
  addUsuario(){
    this.request.globalRequest('post', 'saveUsuarios', this.new_usuario).subscribe(res=>{
      console.log(res);
      this._router.navigate(['/listado-usuarios']);
    });
  }
  cancelar(){
    this._router.navigate(['/listado-usuarios']);
  }

}
