import { Injectable, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable()
export class SettingsService {
  
ajustes: Ajustes = {
  temaUrl:'assets/css/colors/default.css',
  tema:'default'
};
  // tslint:disable-next-line: variable-name
  constructor( @Inject(DOCUMENT) private _document ) { 
    this.cargarAjustes();
  }
  
  guardarAjustes(){
    // console.log("guardado en locals");
    localStorage.setItem('ajustes', JSON.stringify(this.ajustes) )
  }

  cargarAjustes(){
    if(localStorage.getItem('ajustes')){
      this.ajustes = JSON.parse(localStorage.getItem('ajustes'));
      // console.log("cargando de locals");
      this.aplicarTema(this.ajustes.tema);
    }
    else
    {
      this.aplicarTema(this.ajustes.tema);
    }
  }
  aplicarTema( tema: String ){
    let url =`assets/css/colors/${ tema }.css`;
    this._document.getElementById('tema').setAttribute('href',url);
    this.ajustes.tema = tema;
    this.ajustes.temaUrl = url;

    this.guardarAjustes();
  }
  

}
interface Ajustes{
  temaUrl: String;
  tema: String;
}