import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-consulta-empresa',
  templateUrl: './consulta-empresa.component.html',
  styles: []
})
export class ConsultaEmpresaComponent implements OnInit {
 
  empresas: [];
  counters: [];
  rowData: [];
  columnDefs:any;
  
  constructor(
    private request: RequestService,
    private router: Router,
    
    
  ) { 
    
  }
  
  ngOnInit() {
    
    const _self=this;
    this.request.globalRequestW('get', 'infoEmpresasTabla', {}).subscribe(res => {
      this.rowData = res;
      console.log('arrayInfo',this.rowData);
    });
    this.request.globalRequestW('post','Contadores',{}).subscribe( res=> {
      this.counters=res;
      console.log('counter ',this.counters);
    });



    this.columnDefs = [
      {width: 50, cellRenderer : function(params){
        return '<button type="button" class="btn btn-outline-info btn-sm edit-data" (click)="toEdit()">\
        <span class="mdi mdi-pencil"></span>\
        </button>';
        },
        onCellClicked: function (params) {
          _self.toEdit(params.data.id);
        }
      },
      {width: 50, cellRenderer : function(params){
        return '<button type="button" class="btn btn-outline-info btn-sm edit-data" (click)="toEdit()">\
        <span class="mdi mdi-eye"></span>\
        </button>';
        },
        onCellClicked: function (params) {
          _self.toView(params.data.id);
        }
      },
      {width: 50, cellRenderer : function(params){
        return '<button type="button" class="btn btn-outline-success btn-sm edit-data" (click)="toEdit()">\
        <span class="mdi mdi-chart-line"></span>\
        </button>';
        },
        onCellClicked: function (params) {
          _self.toEstadistica(params.data.id);
        }
      },
      {width: 50, cellRenderer : function(params){
        return '<button type="button" class="btn btn-outline-danger btn-sm edit-data">\
        <span class="mdi mdi-delete"></span>\
        </button>';
        },
        onCellClicked: function (params) {
          _self.toAlert(params.data.id);
        }
      },
      {headerName: 'Id', field: 'id',  width: 80, sortable: true, filter: true,resizable: true },
      {headerName: 'Tipo', field: 'multiusuario',  width: 90, sortable: true, filter: true,resizable: true },
      {headerName: 'Nombre Comercial', field: 'nombre',  width: 160, sortable: true, filter: true,resizable: true },
      {headerName: 'Razón Social', field: 'razon',  width: 160, sortable: true, filter: true,resizable: true },
      {headerName: 'Teléfono', field: 'telefono' , width: 120, sortable: true, filter: true,resizable: true },
      {headerName: 'Email Usuario', field: 'mail', width: 120, sortable: true, filter: true,resizable: true},
      {headerName: 'Contacto', field: 'Contacto', width: 150, sortable: true, filter: true,resizable: true},
      {headerName: 'Móvil', field: 'movil', width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Pack Actual', field: 'pack', width: 120, sortable: true, filter: true,resizable: true},
      {headerName: 'Estatus', field: 'Estatus', width: 120, sortable: true, filter: true,resizable: true},
      {headerName: 'F. Activación', field: 'activa', width: 150, sortable: true, filter: true,resizable: true},
      {headerName: 'F. Finalización', field: 'finaliza', width: 150, sortable: true, filter: true,resizable: true},
      
  ];
  }
  
  toEdit(id){
    // console.log('ev',id);
    // console.log('click evet',event.data.id);
   this.router.navigate(['/modifica-empresa/'+id+'/1']);
  }
  toView(id)
  {
   this.router.navigate(['/modifica-empresa/'+id+'/0']);
  }
  toEstadistica(id){
    
    this.router.navigate(['/graficas3-graficas/'+id+"/0"]);
  }
  toAlert(id){
    Swal.fire({
      title: '¿Estas seguro que deseas elimiar esta empresa?',
      text: 'Recuerda que este proceso es irreversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value){
        this.request.globalRequestW('post', 'deleteEmpresa', {id_empresa:id}).subscribe(res => {
          console.log(res);
          Swal.fire('Eliminado','Se ha eliminado la empresa y todas sus vacantes','success');
          this.request.globalRequestW('get', 'infoEmpresasTabla', {}).subscribe(res => {
            this.rowData = res;
            console.log('arrayInfo',this.rowData);
          });
        });
      }
    })
  }
}
