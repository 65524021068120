export class UsuarioDetalle{
    constructor(
        public nombre_u?: string,
        public nom_u?: string,
        public apellido_u?: string,
        public apellido2_u?: string,
        public cargo_u?: string,
        public area_u?: string,
        public celular_u?: string,
        public email_u?: string,
        public empresa_u?: number,
        public rol_u?: number,
        public finicio_u?: string,
        public fretiro_u?: string,
        public estado_u?: number,
        public password_u?:string,
        public token_recovery_u?: string,
        public id_p?: number,
        public conse_p?: number,
        public texto_p?: string,
        public estado_p?: string,
        public icon_p?: string,
        public id_estatus?: number,
        public nombre_estatus?: string,
        public avatar?:string,
    ){}
}
