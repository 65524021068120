import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/service.index';
import { RequestService } from '../../services/shared/request.service';
import { AuthenticatorService } from '../../services/authenticator.service';
import { Router  } from '@angular/router';
import { UsuarioDetalle } from '../../models/UsuarioDetalle';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  usuariodt: UsuarioDetalle = new UsuarioDetalle();
 
  datauser = localStorage.getItem('id_u');
  menu: any = [
    {
        'id_prg':'',
        'titulo': '',
        'icono': '',
        'url': '',
        'submenu': [
            {
                'titulos': '',
                'urls': ''
            }
        ]
    }];
    //  submenu: any = [];
   id: string=  localStorage.getItem('rol_u');
   datosU: any;

  constructor( public _sidebar: SidebarService,  private request: RequestService, private _router: Router, private authenticatorService: AuthenticatorService ) { }

  ngOnInit() {
    // console.log('aaa',localStorage.getItem('token'));
    if(!localStorage.getItem('token'))
    {
      this._router.navigate(['/login']);
    }
    const datos = {
      id: this.datauser
    }
    
    this.request.globalRequest('post', 'infoUsuariosId', datos  ).subscribe((res) => {
      this.usuariodt = res;
      // console.log('datosSide', this.usuariodt); 
      // console.log(localStorage.getItem('token'));
      });
      this.obtenerDatos(); 
  }
  logout(){
    // console.log('oprimido');
    localStorage.removeItem('id_u');
    localStorage.removeItem('empresa_u');
    localStorage.removeItem('rol_u');
    localStorage.removeItem('token');
    this._router.navigate(['/login']);
  }
  obtenerDatos(){
    this.datosU=this.id;
    this.request.globalRequest('post', `getGruposMenu`,  {usuario:this.datosU}).subscribe(res => {
    this.menu = res;
    // this.submenu = this.menu.submenu;
    // console.log(this.menu,'esto es menu');
    
    });
  }
  
}
