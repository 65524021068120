import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';



@Component({
  selector: 'app-modifica-usuarios',
  templateUrl: './modifica-usuarios.component.html',
  styles: [],
})
export class ModificaUsuariosComponent implements OnInit {
  public new_usuario: any;
  public roles:[];
  public pw:string;
  constructor(
    private request:RequestService,
    private _router:Router,
    private _route:ActivatedRoute
  ) 
  {  }

  ngOnInit() {
    this._route.params.subscribe((params:Params)=>{
      console.log(params.id);
      this.request.globalRequest('post','infoUsuariosId',params).subscribe(res=>{
        console.log(res);
        this.new_usuario=res;
        this.pw='';
      });
    });
    this.request.globalRequest('get', 'getRoles',{}).subscribe(res=>{
      this.roles=res;
    });
  }
  editUsuario(){
    this.new_usuario.password_u=this.pw;
    console.log(this.new_usuario);
    this.request.globalRequest('post', 'editUsuarios', this.new_usuario).subscribe(res=>{
      console.log(res);
      this._router.navigate(['/listado-usuarios']);
    });
  }
  cancelar(){
    this._router.navigate(['/listado-usuarios']);
  }
}
