import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-consulta-candidatos',
  templateUrl: './consulta-candidatos.component.html',
  styles: []
})
export class ConsultaCandidatosComponent implements OnInit {
  public candidatos:[];
  public num_paginas: number;
  public max:number;
  public min:number;
  rowData: [];
  public columnDefs: any;
  constructor(
    private request:RequestService,
    private _router:Router,
  ) { }

  ngOnInit() {
    
    this.request.globalRequestW('get','infoCandidatos',{}).subscribe(res=>{
      // console.log(res);
      const _self = this;
      this.candidatos=res;
      this.rowData=res;
      this.num_paginas=Math.ceil(this.candidatos.length/100);
      //console.log(this.num_paginas);
      
      this.columnDefs = [
        {width: 50, cellRenderer : function(params){
          return '<button type="button" class="btn btn-outline-danger btn-sm" >\
                    <span class="mdi mdi-do-not-disturb"></span>\
                  </button>'
          },
          onCellClicked: function (params) {
            _self.retirar(params.data.id);
          }
        },
        {width: 50, cellRenderer : function(params){
          return '<button type="button" class="btn btn-outline-info btn-sm">\
                    <span class="mdi mdi-pencil"></span>\
                  </button>'
          },
          onCellClicked: function (params) {
            _self.toEdit(params.data.id);
          }
        },
        {headerName: 'Id', field: 'id' , width: 70, sortable: true, filter: true },
        {headerName: 'Nombre', field: 'nombre_completo' ,  sortable: true, filter: true, resizable: true},
        {headerName: 'Fecha Registro', field: 'fecha1',  sortable: true, filter: true },
        {headerName: 'Status', field: 'estatus_texto' ,width: 100, sortable: true, filter: true },
        {headerName: 'Email', field: 'email', sortable: true, filter: true},  
      ];
    });
  }
  
  toAdd(){
    this._router.navigate(['/crear-candidatos']);
  }
  toEdit(id){
    this._router.navigate(['/modificar-candidatos/'+id]);
  }
  retirar(id){

  }

}
