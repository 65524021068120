import { Component, OnInit } from '@angular/core';
import { Router  } from '@angular/router';

import { AuthenticatorService } from '../services/authenticator.service';

import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';

declare function init_plugins();
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  message='';
  // permitido: [];
  ingreso =false;
  public form = {
    email:null,
    password:null
  }
  constructor(
   
    private _router: Router,
    private authenticatorService:AuthenticatorService,
    private tokens: TokenService,
    private auth: AuthService
  ) { }


  ngOnInit() {
    init_plugins();
  }


  ingresar()
  {
    this.authenticatorService.login(this.form).subscribe((res: any)=> {
// console.log('aa '+JSON.stringify(res));
  if(res.token !=0)
  {
      this.tokens.handle(res.token);
      
      // this.permitido = res.dataUser[0].permisos[0];
      // console.log("permitido",this.permitido[0].edita_pm);

      localStorage.setItem('id_u', res.dataUser[0].id_u);
      localStorage.setItem('rol_u', res.dataUser[0].rol_u);
      localStorage.setItem('empresa_u', res.dataUser[0].empresa_u);
      // localStorage.setItem('permisos', Object.values(this.permitido[1]));
      
      // console.log("esto1",res.dataUser[0].permisos);
      
      this.auth.changeStatus(true);
          this._router.navigate(['/datos-basicos']);
  }
  else{
    this.ingreso= true;
    this.message='Datos Incorrectos';
    console.log('No entro');
  }
    });  
// console.log("form", this.form);
  }
}
