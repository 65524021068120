import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-modifica-paquetes',
  templateUrl: './modifica-paquetes.component.html',
  styles: []
})
export class ModificaPaquetesComponent implements OnInit {
  public new_paquete: any;
  public valor_pck:number=0;
  public iva_pck: number=0;
  public total_pck:number=0;
  constructor(
    private request:RequestService,
    private _router:Router,
    private _route:ActivatedRoute
  ) {
    this.iva_pck=this.valor_pck*0.16;
    this.total_pck=this.valor_pck+this.iva_pck;
   }

  ngOnInit() {
    this._route.params.subscribe((params:Params)=>{
      console.log(params.id);
      this.request.globalRequestW('post','getPaquetesId',params).subscribe(res=>{
        console.log(res);
        this.new_paquete=res;
        
      });
    });
  }
  editPaquete(){
    this.new_paquete.iva_pck=this.new_paquete.valor_pck*0.16;
    this.new_paquete.total_pck=(this.new_paquete.valor_pck*1)+(this.new_paquete.valor_pck*0.16);
    this.request.globalRequestW('post', 'editPaquetes', JSON.stringify(this.new_paquete)).subscribe(res=>{
      console.log(res);
      this._router.navigate(['/consulta-paquetes']);
    });
  }
  cancelar(){
    this._router.navigate(['/consulta-paquetes']);
  }

}
