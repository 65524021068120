import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { EmpresasDetalle } from '../../models/empresasDetalle';
import {Router} from '@angular/router';

@Component({
  selector: 'app-crear-empresa',
  templateUrl: './crear-empresa.component.html',
  styleUrls: ['./crear-empresa.component.css']
})
export class CrearEmpresaComponent implements OnInit {
 public empresa: EmpresasDetalle;
 paquetes:[];
 mediosPago:[];
 pais:[];
 ciudad:[];
 paisEstado:[];
 areas: [];
 asesores: [];
 validadoMail=false;
 multiusuario:number;
 

  constructor(
    private request: RequestService,
    private router: Router
  ) {
    //this.empresa = new EmpresasDetalle( 0,'', '', 0,'', 0,  '', 0, '', '', '', '', 0, 0, 0, '', '', 0, 0, '','','', '', 0, 0, 1);
    this.empresa = new EmpresasDetalle( 0,'', '', 0,'', 0,  '', 0, '', '', '', '', 0, 0, 0, '', '', 0, 0, '','','', '', 0, 0, 1,'',0,0,'','','','','','','','',0);

  }
   ngOnInit() {
    this.request.globalRequest('get', 'getInfPaquetes', {}).subscribe(res => {
    this.paquetes = res;
    });
    this.request.globalRequest('get', 'getParametrosMediosPago', {}).subscribe(res => {
    this.mediosPago = res;
    });
    this.request.globalRequest('get', 'getPais', {}).subscribe(res => {
    this.pais = res;
    // console.log(res);
    });
    this.request.globalRequestW('get', 'getCiudad', {}).subscribe(res => {
      //es municipio
      this.ciudad = res;
    });
    this.request.globalRequestW('get', 'getPaisEstado', {}).subscribe(res => {
      this.paisEstado = res;
    });
    this.request.globalRequestW('get','getAreas', {}).subscribe(res => {
      // console.log('areas', res);
      this.areas = res;
    });
    this.request.globalRequest('post', 'getInfoUsuariosAsesores', {}).subscribe(res => {
      this.asesores = res;
    });
  }
  saveEmpresa() {
    // console.log( JSON.stringify(this.empresa));
    this.request.globalRequestW('post', 'creaEmpresa', JSON.stringify(this.empresa) ).subscribe(res => {
    //  console.log(res);
     this.router.navigate(['/consulta-empresa']);
     });
  }
  validaUsr(){
    console.log("estoy aca ",this.empresa.email_emp);
    this.request.globalRequestW('post','validaMailUsuario',JSON.stringify(this.empresa.email_emp)).subscribe(res=> {
      console.log(res,"dddd");
      if(res>0) {
        this.validadoMail= false;
      }
      else
      {
        this.validadoMail= true;
        console.log(res,"aaaa");
      }
    });
    
  }
}
