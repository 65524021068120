import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  
  options: any;
  headers: any;
  constructor(
    private http: HttpClient,
    private token:TokenService
  ) { }
  globalRequest(metodo, url, json){
    let request: Observable<any>;
    const token = this.token.get();
    
    this.headers= new HttpHeaders({
      'Content-Type': 'Application/json',
      token
    });
    this.options = { headers: this.headers };
    if(metodo.toLowerCase() === 'get') {
      request = this.http.get(environment.urlApi + url, this.options);
    } else {
      request = this.http.post(environment.urlApi + url,json, this.options);
    }
    return request;
  }
  globalRequestW(metodo, url, json){
    let request: Observable<any>;
    const token = this.token.get();
    
    this.headers= new HttpHeaders({
      'Content-Type': 'Application/json',
      token
    });
    this.options = { headers: this.headers };
    if(metodo.toLowerCase() === 'get') {
      request = this.http.get(environment.urlApiW + url, this.options);
    } else {
      request = this.http.post(environment.urlApiW + url,json, this.options);
    }
    return request;
  }
}

