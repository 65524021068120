import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-modifica-candidatos',
  templateUrl: './modifica-candidatos.component.html',
  styles: []
})
export class ModificaCandidatosComponent implements OnInit {
  public new_candidato: any;
  public estudio_candidato:{};
  public experiencia_candidato:{};
  public columnEstudios:any;
  public columnExperiencia:any;
  public columnDefs:any;
  public idu: number;
  public idc: number;
  public resultado: string;
  public mostrarHistorial=false;
  public MlEnviado=false;
  public rowData2: [];
  
  constructor(
    private request:RequestService,
    private _router:Router,
    private _route:ActivatedRoute,
    
  ) { }

  ngOnInit() {
    const _self=this;
    this._route.params.subscribe((params:Params)=>{
      this.request.globalRequestW('post','infoCandidatosId',params).subscribe(res=>{
        this.new_candidato=res[0];
        this.estudio_candidato=JSON.parse(this.new_candidato.estudio);
        this.experiencia_candidato=JSON.parse(this.new_candidato.experiencia_laboral);
        console.log(res);
      });
      this.idc = params.id;
      this.request.globalRequestW('get', `getLogCambios/${this.idc}/2`, {}).subscribe(res => {
        this.rowData2 = res;
        // console.log('arrayInfo',this.rowData);
      });
    });

    this.columnEstudios = [
      {headerName: 'Estudio', field: 'tituloEstudio' ,  sortable: true, filter: true },
      {headerName: 'Fecha Ingreso', field: 'fechaEstudio',  sortable: true, filter: true },
      {headerName: 'Estatus', field: 'subNivelEstudio' , sortable: true, filter: true },
      {headerName: 'Institución', field: 'institucionUniversidadEstudio', sortable: true, filter: true},  
    ];
    this.columnExperiencia = [
      {headerName: 'Empresa', field: 'empresaExperiencia' ,  sortable: true, filter: true },
      {headerName: 'Cargo', field: 'cargoExperiencia',  sortable: true, filter: true },
      {headerName: 'Inicio', field: 'fechaInicioExperiencia' , sortable: true, filter: true },
      {headerName: 'Fin', field: 'fechaFinExperiencia', sortable: true, filter: true},  
    ];
    this.columnDefs = [
      {headerName: 'Campo', field: 'ncolumna',  width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Original', field: 'original_log',  width: 300, sortable: true, filter: true,resizable: true },
      {headerName: 'Nuevo', field: 'nuevo_log' , width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Fecha', field: 'fecha' , width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Hora', field: 'hora' , width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Usuario', field: 'nombre_u' , width: 150, sortable: true, filter: true,resizable: true },
    ];
    
  }
  editCandidato(){
    this.request.globalRequestW('post', 'editCandidatos', JSON.stringify(this.new_candidato)).subscribe(res=>{
      // console.log(res);
      this._router.navigate(['/consulta-candidatos']);
    });
  }
  cancelar(){
    this._router.navigate(['/consulta-candidatos']);
  }
  enviaMail(idu){
    // console.log('picando',this.new_candidato);
    this.request.globalRequest('get',`mailBody/${idu}/${this.new_candidato.nombre_completo}/${this.new_candidato.email}/${this.new_candidato.credenciales.email}/${this.new_candidato.credenciales.password}`,{}).subscribe(res => {
    this.MlEnviado=true;
    });
  }
  
  enviaMailOferta(idu){
    
   
    this.request.globalRequest('post',`mailBodyCandidatos`,{id:idu}).subscribe(res => {
      this.resultado=res;
      this.MlEnviado=true;
    });
  }
  viewHistory(){
    // console.log("clickado");
    if(this.mostrarHistorial==false)
    {
      this.mostrarHistorial=true;
    }
    else
    {
      this.mostrarHistorial=false;
    }
    
  }
}
