import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { Graficas2Component } from './graficas2/graficas2.component';
import { Graficas3Component } from './graficas3/graficas3.component';
import { Graficas3GraficasComponent } from './graficas3-graficas/graficas3-graficas.component';
import { GraficodonaComponent } from '../components/graficodona/graficodona.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { DatosBasicosComponent } from './datos-basicos/datos-basicos.component';
import { ListadoUsuariosComponent } from './listado-usuarios/listado-usuarios.component';
import { CreaUsuariosComponent } from './crea-usuarios/crea-usuarios.component';
import { CrearEmpresaComponent } from './crear-empresa/crear-empresa.component';
import { ModificaEmpresaComponent } from './modifica-empresa/modifica-empresa.component';
import { ConsultaEmpresaComponent } from './consulta-empresa/consulta-empresa.component';
import { ConsultaCandidatosComponent } from './consulta-candidatos/consulta-candidatos.component';
import { ModificaCandidatosComponent } from './modifica-candidatos/modifica-candidatos.component';
import { CrearPaquetesComponent } from './crear-paquetes/crear-paquetes.component';
import { ModificaPaquetesComponent } from './modifica-paquetes/modifica-paquetes.component';
import { ConsultaPaquetesComponent } from './consulta-paquetes/consulta-paquetes.component';
import { ModificaUsuariosComponent } from './modifica-usuarios/modifica-usuarios.component';
import { ConsultaPostuladosComponent } from './consulta-postulados/consulta-postulados.component';
import { DetallePostuladosComponent } from './detalle-postulados/detalle-postulados.component';
import { ConsultaVacantesComponent } from './consulta-vacantes/consulta-vacantes.component';
import { ModificaVacantesComponent } from './modifica-vacantes/modifica-vacantes.component';
import { NotificacionesEmpresaComponent } from './notificaciones-empresa/notificaciones-empresa.component';
import { NotificacionesCandidatosComponent } from './notificaciones-candidatos/notificaciones-candidatos.component';


const pagesRoutes: Routes = [
    {
        path:'',
        component:PagesComponent,
        children: [
            {path:'dashboard',component:DashboardComponent},
            {path:'progress',component:ProgressComponent},
            {path:'graficas1',component:Graficas1Component},
            {path:'graficas2',component:Graficas2Component},
            {path:'graficas3',component:Graficas3Component},
            {path:'graficas3-graficas/:id_empresa/:id_paquete',component:Graficas3GraficasComponent},
            {path:'graficasDona',component:GraficodonaComponent},
            {path:'account-settings',component:AccountSettingsComponent},
            {path:'datos-basicos',component:DatosBasicosComponent},
            {path:'listado-usuarios',component:ListadoUsuariosComponent},
            {path:'crear-usuarios',component:CreaUsuariosComponent},
            {path:'crear-empresa',component:CrearEmpresaComponent},
            {path:'modifica-empresa/:id/:view',component:ModificaEmpresaComponent},
            {path:'consulta-empresa',component:ConsultaEmpresaComponent},
            {path:'consulta-candidatos',component:ConsultaCandidatosComponent},
            {path:'modificar-candidatos/:id',component:ModificaCandidatosComponent},
            {path:'crear-paquetes',component:CrearPaquetesComponent},
            {path:'modificar-paquetes/:id',component:ModificaPaquetesComponent},
            {path:'consulta-paquetes',component:ConsultaPaquetesComponent},
            {path:'modificar-usuarios/:id',component:ModificaUsuariosComponent},
            {path:'consulta-postulados',component:ConsultaPostuladosComponent},
            {path:'detalle-postulados/:id',component:DetallePostuladosComponent},
            {path:'consulta-vacantes',component:ConsultaVacantesComponent},
            {path:'modificar-vacantes/:id',component:ModificaVacantesComponent},
            {path:'notificaciones-candidatos',component:NotificacionesCandidatosComponent},
            {path:'notificaciones-empresa',component:NotificacionesEmpresaComponent},
            {path:'',redirectTo:'/login',pathMatch:'full'}
        ]
    }
];

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );
