import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-graficas3',
  templateUrl: './graficas3.component.html',
  styles: []
})
export class Graficas3Component implements OnInit {
 
  empresas: [];
  rowData: [];
  columnDefs:any;
  
  constructor(
    private request: RequestService,
    private router: Router,
    
    
  ) { 
    
  }
  
  ngOnInit() {
    const _self=this;
    this.request.globalRequestW('get', 'infoEmpresasTabla', {}).subscribe(res => {
      this.rowData = res;
      // console.log('arrayInfo',this.rowData);
    });



    this.columnDefs = [
      {width: 50, cellRenderer : function(params){
        return '<button type="button" class="btn btn-outline-success btn-sm edit-data" (click)="toEdit()">\
        <span class="mdi mdi-chart-line"></span>\
        </button>';
        },
        onCellClicked: function (params) {
          _self.toEstadistica(params.data.id);
        }
      },
      {headerName: 'Id', field: 'id',  width: 80, sortable: true, filter: true,resizable: true },
      {headerName: 'Razón Social', field: 'nombre',  width: 160, sortable: true, filter: true,resizable: true },
      {headerName: 'Teléfono', field: 'telefono' , width: 120, sortable: true, filter: true,resizable: true },
      {headerName: 'Email Usuario', field: 'mail', width: 120, sortable: true, filter: true,resizable: true},
      {headerName: 'Contacto', field: 'Contacto', width: 150, sortable: true, filter: true,resizable: true},
      {headerName: 'Móvil', field: 'movil', width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Pack Actual', field: 'pack', width: 120, sortable: true, filter: true,resizable: true},
      {headerName: 'Estatus', field: 'Estatus', width: 120, sortable: true, filter: true,resizable: true},
      {headerName: 'F. Activación', field: 'activa', width: 150, sortable: true, filter: true,resizable: true},
      {headerName: 'F. Finalización', field: 'finaliza', width: 150, sortable: true, filter: true,resizable: true},
      
  ];
  }
  
  toEstadistica(id){
    this.router.navigate(['/graficas3-graficas/'+id+"/0"]);
  }
}
