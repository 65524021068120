import { Component, OnInit } from '@angular/core';
//import { timingSafeEqual } from 'crypto';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { Grafica } from '../../models/graficas';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-graficas1',
  templateUrl: './graficas1.component.html',
  styles: []
})


export class Graficas1Component implements OnInit {
  
  newEstVacante: Grafica;
  //graficos: any = {};
  public titulo:'';
  public OptionsVacantes: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: 'VACANTES PUBLICADAS',
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Periodo de tiempo',
        },
      }], 
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Cantidad',
        },
        ticks: {
          min : 0,
          stepSize: 1,
        }
      }] 
    },
  };
  public DataVacantes: ChartDataSets[] = 
  [
    {
      data:[],
      label:null,
      backgroundColor: 'rgba(51, 216, 255,.7)',
      borderColor: 'rgb(51, 216, 255)',
    },
  ]
  ;
  public TotalGrafica:0;
  public LabelsGraficas: [];
  public paquetesTabla:[];
  public columnDefs:any;
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'line';
  public barChartLegend = true;
  
  fechaInicio: any;
  fechaFin:any;

  constructor(
    private request:RequestService,
    private _router:Router,
  ) { }
  
  ngOnInit() {
    this.request.globalRequestW('post', 'GraficasPortal', {fInicio:this.fechaInicio, fFin:this.fechaFin}).subscribe(res => {
      console.log(res);
      this.LabelsGraficas=res['arrayFechas'];
      this.DataVacantes[0]=res['Vacantes'];
      this.DataVacantes[1]=res['Postulados'];
      this.DataVacantes[2]=res['Candidatos'];
      this.DataVacantes[3]=res['Empresas'];
      //this.TotalGrafica=res['Vacantes']['data']((a, b) => a + b, 0);
    });
  }
  estVacante(){
    this.request.globalRequestW('post', 'GraficasPortal', {fInicio:this.fechaInicio, fFin:this.fechaFin}).subscribe(res => {
      console.log(res);

      this.LabelsGraficas=res['arrayFechas'];
      this.DataVacantes[0]=res['Vacantes'];
      this.DataVacantes[1]=res['Postulados'];
      this.DataVacantes[2]=res['Candidatos'];
      this.DataVacantes[3]=res['Empresas'];
    });
  }

}
