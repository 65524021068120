import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notificaciones-candidatos',
  templateUrl: './notificaciones-candidatos.component.html',
  styles: []
})
export class NotificacionesCandidatosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  enviaMail(id){
    console.log("entro");
  }
  enviaMailOferta(id){
    console.log("entro");
  }
}
