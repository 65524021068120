import { Injectable } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';

@Injectable()
export class SidebarService {
//  menu: any = [
//   {
//       'id_prg':'',
//       'titulo': '',
//       'icono': '',
//       'url': '',
//       'submenu': [
//           {
//               'titulos': '',
//               'urls': ''
//           }
//       ]
//   }];
//  //  submenu: any = [];
//  id: string=  localStorage.getItem('rol_u');
//  datosU: any;
  constructor(private request: RequestService ) { 
    // this.obtenerDatos(); 
  }
  
  // ngOnInit() {
    
  // }
  
  // obtenerDatos(){
  //   this.datosU=this.id;
  //   this.request.globalRequest('post', `getGruposMenu`,  {usuario:this.datosU}).subscribe(res => {
  //   this.menu = res;
  //   console.log(this.menu,'esto es menu');
  //   });
  // }
}
