export class EmpresasDetalle{
    constructor(
        public id:number,
        public nombre_comercial: string,
        public logo: string,
        public tipo_empresa: number,
        public nombre_contrato: string,
        public puesto_emp: number,
        // public tipo_empresa: string,
        public direccion_emp: string, //cambiar por direccion
        public codigo_postal: number,
        public telefono_oficina: string,
        public telefono_movil: string,
        public email_contacto: string,
        public url_emp: string,
        public pais_emp: number,
        public municipio: number,
        public ciudad_emp: number,
        public fecha_registro: any,
        public ffinaliza_emp: any,
        public id_paquete: number,
        public fpago_emp: number,
        public rfc_emp: string,//cambiar a rfc
        public descripcion_emp: string,//cambiar por descripcion
        public representanteleg_emp: string,
        public emailrep_emp: string,
        public vlrpagado_emp: number,
        public valor_pck: number,
        public estado_emp: number,
        /////////Agregados
        public factivacion_emp:any,
        public area_emp:number,
        public pack_emp:number,
        public movil_emp:string,
        public nombrecontrato_emp:string,
        public email_emp:string,
        public telefono_emp:string,
        public razon_social_emp:string,
        public nombrecomercial_emp:string,
        public asesor:string,
        public pass_usr:string,
        public multiusuario:number,


    ){}
}

      