import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-consulta-paquetes',
  templateUrl: './consulta-paquetes.component.html',
  styles: ['th {text-align:ceneter;}']
})
export class ConsultaPaquetesComponent implements OnInit {
  public paquetes:[];
  public columnDefs:any;
  constructor(
    private request:RequestService,
    private _router:Router,
  ) { }

  ngOnInit() {
    const _self=this;
    this.request.globalRequestW('get','getPaquetes',{}).subscribe(res=>{
      //console.log(res);
      this.paquetes=res;
    });

    this.columnDefs = [
      {width: 50, cellRenderer : function (params){
        return '<button type="button" class="btn btn-outline-info btn-sm" >\
                  <span class="mdi mdi-pencil"></span>\
                </button>'
        },
        onCellClicked: function (params) {
          _self.toEdit(params.data.id);
        }
      },
      {headerName: 'Nombre', field: 'descripcion' , width: 120, sortable: true, filter: true },
      {headerName: 'Vacantes', field: 'vacantes_pck' , width: 120, sortable: true, filter: true },
      //{headerName: 'Visitantes', field: 'visitantes_pck',  width: 100, sortable: true, filter: true },
      {headerName: 'Destacados', field: 'destacadas_pck' ,width: 120, sortable: true, filter: true },
      {headerName: 'Urgentes', field: 'urgentes_pck',width: 120, sortable: true, filter: true},
      {headerName: 'C. Base', field: 'consbase_pck',  width: 120,sortable: true, filter: true},
      {headerName: 'C. Postulados', field: 'conspostu_pck', width: 120, sortable: true, filter: true},
      {headerName: 'Días Publicados', field: 'vigencia_p_pck',  width: 120,sortable: true, filter: true},
      {headerName: 'Estatus', field: 'estatus_pck', width: 120, sortable: true, filter: true},
      {headerName: 'Precio', field: 'total_pck',  width: 120,sortable: true, filter: true},
  ];
  }
  toAdd(){
    this._router.navigate(['/crear-paquetes']);
  }

  toGrafica(event){
    this._router.navigate(['/graficas2/'+event]);
  }
 
  toEdit(event){
    //console.log(event);
    this._router.navigate(['/modificar-paquetes/'+event]);
  }

  // toEdit(id){
  //   this._router.navigate(['/modificar-paquetes/'+id]);
  // }

}
