import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Empresa } from '../../models/empresas';
import { Paquete } from '../../models/paquetes';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-modifica-empresa',
  templateUrl: './modifica-empresa.component.html',

  styles: []
})
export class ModificaEmpresaComponent implements OnInit {

  columnDefs:any;
  columnDefs2:any;
  columnDefs3:any;
  rowData: [];
  rowData2: [];
  rowData3: [];
  empresas: Empresa;
  paquetes: [];
  mediosPago: [];
  mediosNotas: [];
  pais: [];
  ciudad=[];
  paisEstado: [];
  editados: [];
  infoPaquetes: Paquete;
  id: number;
  Estatuses:[];
  areas: [];
  asesores: [];
  multiusuario:number;
  paquete_id:number;
  paquete_tipo:number;
  idUser = localStorage.getItem('id_u');
  idVacante:any;
  
  msnNota = false;
  actualizar = false;
  mostrarHistorial=false;
  mostrarNotas=false;
  mostrarPostulaciones=true;
  MlEnviado=false;
  verBoton: string;
  resultado: string;
  btn = false;
  message = 'Datos actualizados';
  messageNota:string;
  datosModificar: FormGroup;
  datosNotas: FormGroup;

  constructor(
    private request: RequestService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _route:ActivatedRoute,
  ) {
    this.recibirID();
    this.createFormulario();
    this.createFormularioNotas();
    this.obtenerDatos();
  }
   ngOnInit() {
    this.opciones();
    // console.log(this.idUser,' aaaa');
    //console.log('aab',this.verBoton=this.route.snapshot.paramMap.get('view'));
    this.verBoton=this.route.snapshot.paramMap.get('view');
    if(this.verBoton == '1')
    {
      this.btn = true;
    }
    this.getHistorial();
    this.getNotas();
    this.getPostuladosyV();
   
  }
   getHistorial()
  {
    this.request.globalRequestW('get', `getLogCambios/${this.id}/1`, {}).subscribe(res => {
      this.rowData2 = res;
      // console.log('arrayInfo',this.rowData);
    });
  }
  getNotas()
  {
    this.request.globalRequest('get', `getComentario/${this.id}`, {}).subscribe(res => {
      this.rowData3 = res;
      console.log('picando',this.empresas.id);
    });
  }
  getPostuladosyV()
  {
    this.request.globalRequestW('get', `infoVacantesId/${this.id}`, {}).subscribe(res => {
      console.log('la respuesta es',res)
      this.rowData = res;
      this.idVacante=res[0].idVacante;
      // console.log("idv",res[0].idVacante);
    });
  }

  
  createFormulario()
  {
    this.datosModificar = this.formBuilder.group({
      id_emp: [this.id],
      nombrecomercial_emp:['',[Validators.required]],
      logo_emp: [''],
      nombrecontrato_emp: [''],
      puesto_emp: [''],
      razon_social_emp: [''],
      direccion_emp: [''],
      cpostal_emp: [''],
      telefono_emp: [''],
      movil_emp: [''],
      email_emp: [''],
      url_emp: [''],
      pais_emp: [''],
      estado_emp: [''],
      ciudad_emp: [''],
      municipio:[''],
      factivacion_emp: [''],
      ffinaliza_emp: [''],
      pack_emp: [''],
      fpago_emp: [''],
      rfc_emp: [''],
      descripcion_emp: [''],
      representanteleg_emp: [''],
      emailrep_emp: [''],
      vlrpagado_emp: [''],
      est_emp: [''],
      area_emp: [''],
      estado:[''],
      asesor:[''],
      multiusuario:[''],
    });
  
    
  
      this.columnDefs = [
        {headerName: '#', field: 'No',  sortable: true, filter: true,resizable: true },
        {headerName: 'Id', field: 'id',  sortable: true, filter: true,resizable: true },
        {headerName: 'Folio', field: 'Folio',  sortable: true, filter: true,resizable: true },
        {headerName: 'Título Vacante', field: 'vacante',  sortable: true, filter: true,resizable: true },
        {headerName: 'Fecha Publicacion', field: 'fecha' , sortable: true, filter: true,resizable: true },
        {headerName: 'Sucursal', field: 'Sucursal' , sortable: true, filter: true,resizable: true },
        {headerName: 'Postulados', field: 'postulaciones' , sortable: true, filter: true,resizable: true },
        {headerName: 'Vistas', field: 'vistas' , sortable: true, filter: true,resizable: true },
        {headerName: 'Estatus', field: 'estatus' , sortable: true, filter: true,resizable: true },
        
        
    ];
    this.columnDefs2 = [
       
      {headerName: 'Campo', field: 'ncolumna',  width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Original', field: 'original_log',  width: 300, sortable: true, filter: true,resizable: true },
      {headerName: 'Nuevo', field: 'nuevo_log' , width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Fecha', field: 'fecha' , width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Hora', field: 'hora' , width: 150, sortable: true, filter: true,resizable: true },
      {headerName: 'Usuario', field: 'nombre_u' , width: 150, sortable: true, filter: true,resizable: true },
      
      
  ];
  this.columnDefs3 = [
       
    {headerName: 'Fecha', field: 'fecha_cmt',  width: 100, sortable: true, filter: true,resizable: true },
    {headerName: 'Medio', field: 'texto_p',  width: 110, sortable: true, filter: true,resizable: true },
    {headerName: 'Nombre', field: 'nombre_cmt' , width: 150, sortable: true, filter: true,resizable: true },
    {headerName: 'Comentario', field: 'comentario_cmt' , width: 500, height:200, sortable: true, filter: true, resizable: true, editable:true, wrapText:true,autoHeight:true },
    {headerName: 'Usuario', field: 'nombre_u' , width: 150, sortable: true, filter: true,resizable: true },
 ];

}
  createFormularioNotas()
  {
    this.datosNotas = this.formBuilder.group({
      medioNota: [''],
      contactoNota: [''],
      comentarioNota: [''],
      clienteNota: [this.id],
    });
  }
  recibirID(){
    this.route.params.subscribe(params => {
    //console.log(params);
    //obtengo el id a modificar
    this.id = params.id;
    });
  }
  obtenerDatos(){
    this.request.globalRequestW('get', `infoEmpresasId/${this.id}`, {}).subscribe(res => {
    //console.log(res);
      this.empresas = res;
      console.log("respuesta",this.empresas);
      this.datosModificar.controls['nombrecomercial_emp'].setValue(  this.empresas.nombre_comercial);
      this.datosModificar.controls['logo_emp'].setValue(  this.empresas.logo);
      this.datosModificar.controls['nombrecontrato_emp'].setValue(  this.empresas.nombre_contrato);
      this.datosModificar.controls['puesto_emp'].setValue(  this.empresas.puesto_emp);
      this.datosModificar.controls['razon_social_emp'].setValue(  this.empresas.razon_social);
      this.datosModificar.controls['direccion_emp'].setValue(  this.empresas.direccion);
      this.datosModificar.controls['cpostal_emp'].setValue(  this.empresas.codigo_postal);
      this.datosModificar.controls['telefono_emp'].setValue(  this.empresas.telefono_oficina);
      this.datosModificar.controls['movil_emp'].setValue(  this.empresas.telefono_movil);
      this.datosModificar.controls['email_emp'].setValue(  this.empresas.email_contacto);
      this.datosModificar.controls['url_emp'].setValue(  this.empresas.url_emp);
      this.datosModificar.controls['pais_emp'].setValue(  this.empresas.pais_emp);
      this.datosModificar.controls['estado_emp'].setValue(  this.empresas.estado_emp);
      this.datosModificar.controls['estado'].setValue(  this.empresas.estado);
      this.datosModificar.controls['ciudad_emp'].setValue(  this.empresas.ciudad_emp);
      this.datosModificar.controls['municipio'].setValue(  this.empresas.municipio);
      this.datosModificar.controls['factivacion_emp'].setValue(  this.empresas.fecha_registro);
      this.datosModificar.controls['ffinaliza_emp'].setValue(  this.empresas.ffinaliza_emp);
      this.datosModificar.controls['pack_emp'].setValue(  this.empresas.id_paquete);
      this.datosModificar.controls['fpago_emp'].setValue(  this.empresas.fpago_emp);
      this.datosModificar.controls['rfc_emp'].setValue(  this.empresas.rfc);
      this.datosModificar.controls['descripcion_emp'].setValue(  this.empresas.descripcion);
      this.datosModificar.controls['representanteleg_emp'].setValue(  this.empresas.representanteleg_emp);
      this.datosModificar.controls['emailrep_emp'].setValue(  this.empresas.emailrep_emp);
      this.datosModificar.controls['vlrpagado_emp'].setValue(  this.empresas.vlrpagado_emp);
      this.datosModificar.controls['area_emp'].setValue(  this.empresas.id_actividad);
      this.datosModificar.controls['asesor'].setValue(  this.empresas.asesor);
      this.datosModificar.controls['multiusuario'].setValue(  this.empresas.multiusuario);
     
     });
  }
      
  opciones(){
      this.request.globalRequestW('get', 'getPaisEstado', {}).subscribe(res => {
        // console.log(res,"es pais");
        this.paisEstado = res;
      });
      this.request.globalRequestW('get', 'getPaquetes', {}).subscribe(res => {
        this.paquetes = res;
      });
      this.request.globalRequest('get', 'getParametrosMediosPago', {}).subscribe(res => {
        this.mediosPago = res;
      });
      this.request.globalRequest('get', 'getParametrosMediosNotas', {}).subscribe(res => {
        this.mediosNotas = res;
        console.log('hola',this.mediosNotas);
      });
       this.request.globalRequest('get', 'getPais', {}).subscribe(res => {
      this.pais = res;
      //console.log(res);
      });
      this.request.globalRequest('get','getAreas', {}).subscribe(res => {
        console.log('areas', res);
        this.areas = res;
      });
      
   
      this.request.globalRequest('post', 'getInfoUsuariosAsesores', {}).subscribe(res => {
        this.asesores = res;
      });
      
     
  }
  getMunicipios(id){
    this.request.globalRequestW('post', 'getCiudad2', {id_estado:id}).subscribe(res => {
      //console.log(res);
     this.ciudad = res;
   });
  }
   

  formAddPack(){
    document.getElementById('form-add-pck').style.display='inline';
    document.getElementById('btn-add-pck').style.display='none';
   
  }
  cancelAddPack(){
    document.getElementById('form-add-pck').style.display='none';
    document.getElementById('btn-add-pck').style.display='inline';
   
  }
  saveEmpresa() {
    this.datosModificar.value.id_emp = this.id;
    console.log(this.datosModificar);
    this.request.globalRequestW('post', 'actualizaEmpresa', this.datosModificar.value ).subscribe(res => {
    //console.log(res);
     this.actualizar = true;
     });
  }
  saveNota() {
    this.datosNotas.value.id_cmt = this.id;
    // console.log(this.datosNotas);
    this.request.globalRequest('post', 'saveNota', this.datosNotas.value ).subscribe(res => {
    console.log("aaa ",res);
    this.msnNota = true;
    this.messageNota = res.msn;
   
    this.datosNotas.reset();
     });
    
  }
  savePaquete(){
    this._route.params.subscribe((params:Params)=>{
      this.request.globalRequestW('post', 'actualizaPaquete',{id_empresa:params.id,id_paquete:this.paquete_id,tipo:this.paquete_tipo} ).subscribe(res => {
        document.getElementById('form-add-pck').onreset;
        document.getElementById('form-add-pck').style.display='none';
        document.getElementById('btn-add-pck').style.display='inline';
        var ok= document.createElement('span');
        ok.setAttribute('class','fa fa-check-circle');
        ok.setAttribute('style','color:green');
        document.getElementById("correcto").appendChild(ok);

      });
    });
  }
  enviaMail(id){
    console.log('picando',this.empresas.credenciales.password);
    this.request.globalRequest('get',`mailBody/${id}/${this.empresas.nombre_comercial}/${this.empresas.email_contacto}/${this.empresas.credenciales.email}/${this.empresas.credenciales.password}`,{}).subscribe(res => {
      this.resultado=res;
      this.MlEnviado=true;
    });
  }

  enviaMailOferta(id){
    // console.log("pico ",this.idVacante);
    //console.log('picando',this.empresas.nombre_comercial);
    this.request.globalRequestW('post',`mailBodyEmpresas`,{id:this.idVacante}).subscribe(res => {
      this.resultado=res;
      this.MlEnviado=true;
      
    });
  }
  viewHistory(){
    // console.log("clickado");
    if(this.mostrarHistorial == false)
    {
      this.getHistorial();
      this.mostrarHistorial=true;
    }
    else
    {
      this.mostrarHistorial=false;
    }
  }
  viewNotas(){
    // console.log("clickado");
    if(this.mostrarNotas == false)
    {
      this.getNotas();
      this.mostrarNotas = true;
    }
    else{
      this.mostrarNotas = false;
    }
  }
  viewPostulaciones(){
    // console.log("clickado");
    if(this.mostrarPostulaciones == false)
    {
      this.getPostuladosyV();
      this.mostrarPostulaciones = true;
    }
    else{
      this.mostrarPostulaciones = false;
    }
  }
  // cancelar(){
  //   this._router.navigate(['/consulta-empresa']);
  // }

}
