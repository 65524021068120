import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/shared/request.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-consulta-vacantes',
  templateUrl: './consulta-vacantes.component.html',
  styleUrls: []
})
export class ConsultaVacantesComponent implements OnInit {
  public vacantes: [];
  public contadores:any;
  public columnDefs:any;
  constructor(
    private request:RequestService,
    private _router:Router,
  ) {
    this.contadores= {
      'activas':0,
      'inactivas':0,
      'guardadas':0,
      'editadas':0,
      'plantilla':0,
      'total':0,
  
    };
   }
  ngOnInit(): void {
    const _self=this;
    this.request.globalRequestW('get','getVacantes',{}).subscribe(res=>{
      console.log(res);
      this.vacantes=res;
    });
    this.request.globalRequestW('get','contadoresVacantes',{}).subscribe(res=>{
      console.log(res);
      this.contadores=res;
    });
    this.columnDefs = [
      {width: 50, cellRenderer : function(params){
        return '<button type="button" class="btn btn-outline-info btn-sm" >\
                  <span class="mdi mdi-pencil">\
                </span></button>'
        },
        onCellClicked: function (params) {
          _self.toEdit(params.data.id);
        }
      },
      {headerName: 'Id', field: 'id' ,width:120, sortable: true, filter: true,resizable: true },
      {headerName: 'Titulo Vacante', field: 'titulo' , sortable: true, filter: true,resizable: true },
      {headerName: 'Fecha publicación', field: 'fecha_publicacion2' , sortable: true, filter: true,resizable: true },
      {headerName: 'Estatus', field: 'estado' , sortable: true, filter: true,resizable: true },  
      {headerName: 'Empresa', field: 'nombre_comercial' , sortable: true, filter: true,resizable: true },
      {headerName: 'Paquete', field: 'descripcion' , sortable: true, filter: true,resizable: true },  
      {headerName: 'Fecha Paquete', field: 'fecha_paquete2' , sortable: true, filter: true,resizable: true },          
    ];
    

  }
  toEdit(id){
    this._router.navigate(['/modificar-vacantes/'+id]);
  }

}
