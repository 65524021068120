import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AgGridModule } from 'ag-grid-angular';

//rutas
import { APP_ROUTES } from './app.routes';

//modules
import {PagesModule } from './pages/pages.module';


//servicios
import { ServiceModule } from './services/service.module';
 import { HttpClientModule} from '@angular/common/http';

//componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';


//temporalmente
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
     AppComponent,
    LoginComponent,
    RegisterComponent,

  ],
  imports: [
     BrowserModule,
    APP_ROUTES,
    PagesModule,
    FormsModule,
    ServiceModule,
    // MatPaginatorModule,
    //AgGridModule.withComponents([]),
     HttpClientModule,
    AgGridModule.withComponents([])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
