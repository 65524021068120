import { Component, ViewChild, OnInit   } from '@angular/core';
import { SettingsService } from './services/service.index';

// declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'ClubVacante';

 
  ngOnInit(): void {
  
  }
}
